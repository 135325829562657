// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1728511072114.5212";
}
// REMIX HMR END

import { useActionData, Form, useNavigation } from "@remix-run/react";
import { json } from "@remix-run/node";
import { login, createUserSession } from "~/utils/auth.server";
import { Card, CardBody, CardHeader, CardFooter, Input, Button } from "@nextui-org/react";
export const action = async ({
  request
}) => {
  const form = await request.formData();
  const username = form.get("username");
  const password = form.get("password");
  const redirectTo = form.get("redirectTo") || "/";
  if (typeof username !== "string" || typeof password !== "string" || typeof redirectTo !== "string") {
    return json({
      error: "Ungültige Formulardaten"
    }, {
      status: 400
    });
  }
  const user = await login({
    username,
    password
  });
  if (!user) {
    return json({
      error: "Ungültige Anmeldedaten"
    }, {
      status: 400
    });
  }
  return createUserSession(user.id, redirectTo);
};
export default function Login() {
  _s();
  const actionData = useActionData();
  const navigation = useNavigation();
  return <div className="flex justify-center items-center h-full">
            <Card className="w-full max-w-md">
                <CardHeader className="flex justify-center pb-0">
                    <h1 className="text-2xl font-bold">Anmelden</h1>
                </CardHeader>
                <CardBody className="py-5">
                    <Form method="post" className="space-y-4">
                        <Input label="Benutzername" placeholder="Geben Sie Ihren Benutzernamen ein" name="username" isRequired fullWidth />
                        <Input label="Passwort" placeholder="Geben Sie Ihr Passwort ein" type="password" name="password" isRequired fullWidth />
                        <Button type="submit" color="primary" isLoading={navigation.state === "submitting"} fullWidth>
                            {navigation.state === "submitting" ? "Anmelden..." : "Anmelden"}
                        </Button>
                    </Form>
                </CardBody>
                <CardFooter className="flex-col items-center pt-0">
                    {actionData?.error && <p className="text-danger mb-4">{actionData.error}</p>}
                </CardFooter>
            </Card>
        </div>;
}
_s(Login, "e3rMULficn7ldQYYArv00m53mwQ=", false, function () {
  return [useActionData, useNavigation];
});
_c = Login;
Login.handle = {
  hideLayout: true
};
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;